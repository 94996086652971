.homeorderwrapper{
    background-color: #fff;
    border-radius: 4px;
    padding: 35px 10px 40px 15px;
    &__icon{
    font-size: 24px;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    color: #fff;
    }
    &__desc{
        &--order{
            font-size: 14px;
            font-weight: 700;
        }
        &--number{
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: -5px;
        }
    }
}