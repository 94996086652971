.carouselCard {
  background: #ffffff;
  border: 0.6px solid #e0e0e0;
  border-radius: 4px;
  padding: 18px 18px 29px 18px;

  &__category {
    border-bottom: 0.6px solid #e0e0e0;
    &--name {
      font-family: "Lato";
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #495058;
    }
    &--icons {
      &--editicon {
        height: 20px;
        width: 20px;
        color: #495058;
        margin-right: 15px;
        cursor: pointer;
      }
      &--crossicon {
        cursor: pointer;
        font-size: 10px;
        border: 1px solid #c23e3e;
        color: #c23e3e;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 3px;
      }
    }
  }

  &__image,
  &__title,
  &__desc,
  &__link {
    padding-bottom: 10px;
    &--name {
      font-family: "Lato";
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #495058;
    }
  }

  &__image {
    padding-top: 21px;
    padding-bottom: 21px;
    &--name {
      margin-right: 61px;
    }
    &--img {
      border: 0.6px solid #e0e0e0;
      padding: 8px 11px;
    }
  }

  &__title,
  &__desc,
  &__link {
    padding-bottom: 0px;
    p {
      font-family: "Lato";
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: #495058;
      margin-bottom: 0;
    }
  }

  &__title {
    &--name {
      margin-right: 74px;
    }
  }

  &__desc {
    &--name {
      margin-right: 35px;
    }
  }

  &__link {
    &--name {
      margin-right: 76px;
    }
    a {
      word-break: break-all;
      font-size: 12px;
      margin-left: 8px;
      text-decoration: none;
    }
    p {
      font-size: 9px;
    }
  }
}

.modal-content {
  width: 386px !important;
}

.modal__delete {
  font-weight: 700;
  font-size: 20px;
  color: #495058;
  margin-bottom: 15px;
}

.modal__para {
  font-family: "Lato";
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #495058;
  margin-bottom: 40px;
}

.delete__body {
  padding: 34px 17px 11px 34px !important;
}
