.addproductwrapper {
  background-color: #e5e5e5;
  padding: 20px 12px 20px 40px;
  &__background {
    background-color: #fff;
    padding: 27px 27px 60px 27px;
    border-radius: 4px;
    &--title {
      color: #383e50;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
      border-bottom: 1px solid #d0d0d0;
      font-family: "Quicksand", "sans-serifs";
    }
    &--dragdroptitle {
      color: #c23e3e;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 12px;
      span {
        margin-left: 9px;
      }
    }
    &--addbtn {
      margin-top: 28px;
      @include btn(#fff);
      border: 1px solid #005aab;
      padding: 11px 34px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__ingredientused {
    margin: 30px 0 30px 0;
    font-size: 18px;
    color: #383e50;
    font-weight: 700;
    font-family: "Quicksand", "sans-serifs";
    border-bottom: 1px solid #d0d0d0;
  }
  &__addbtn {
    // margin-top: 28px;
    @include btn(#fff);
    border: 1px solid #005aab;
    padding: 11px 38px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
}
select {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  border: none;
  background: #efefef;
  color: #495058;
  padding: 0 10px;

  &:focus {
    outline: none !important;
  }
}
.addproduct-dragdrop {
  .drapanddrop-content {
    padding: 65px 145px;
    text-align: center;
  }
}
.dragzoneimg {
  position: relative;
}
.dragzoneimg-icon {
  position: absolute;
  color: #e62222 !important;
  font-size: 12px;
}
.overflowaddproduct {
  height: 100px;
  overflow-y: scroll;
}
