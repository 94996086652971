.footer {
  background-color: color(secondary, base);
  color: color(text-white);

  &__blocks {
    padding: 58px 0px 38px 0;

    &--header {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: color(text-white);
    }

    &--content {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        // margin: 0 0 10px 0;
        padding: 0;

        a {
          color: color(text-white);
          letter-spacing: 0.035em;
          text-decoration: underline !important;
        }
      }
    }

    &--quick-links {
      padding-left: 97px !important;
    }

    &--contact {
      padding-left: 70px !important;
      .icon {
        font-size: 18px;
      }
    }

    &--social-media {
      padding-left: 90px !important;
      .icon {
        font-size: 24px;
      }
    }
  }

  &__bottom {
    border-top: 2px solid color(text-white);
    padding: 16px 0;

    &--center {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    &__blocks {
      padding: 20px;

      &--about {
        padding-left: 20px !important;
      }
      &--quick-links {
        padding-left: 20px !important;
      }

      &--contact {
        padding-left: 20px !important;
      }

      &--social-media {
        padding-left: 20px !important;
      }
    }
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-13 {
  margin-right: 13px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}
