.reportcard {
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  &__wrapper {
    position: relative;
    &--heading {
      position: absolute;
      top: -6%;
      left: 10%;
      background: white;
      width: 16%;
      text-align: center;
      font-size: 12px;
      color: #000000;
      font-family: "Lato";
      font-weight: 500;
    }
  }

  &__content {
    padding: 12px 83px 30px 60px;
    font-family: "Lato";
    font-size: 14px;
    letter-spacing: 0.02em;

    &--single {
      padding-top: 10px;
    }

    &--left {
      font-weight: 600;
      color: #383e50;
    }
    &--right {
      font-weight: 400;
      color: #495058;
    }
  }
}
