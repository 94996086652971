.customerwrapper {
    background-color: #e5e5e5;
    padding: 20px 12px 20px 40px;
    &__title {
      color: #383e50;
      font-size: 24px;
      font-weight: 700;
    }
    &__background {
      background-color: #fff;
      padding: 25px;
      border-radius: 4px;
      &--customerheading{
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 20px;
      }
    }
    &__export{
        background-color: #005AAB;
        border-radius: 4px;
        padding: 10px;

        p{
            font-size: 14px;
            font-family: "Quicksand","sans-serif";
            color: #fff;
            font-weight: 600;
            margin-bottom: 0;
        }
        &--icon{
            margin: 4px 0 0 12px ;
            color: #fff;
        }
    }
    .customerlistheading,.customerlistleft{
        text-align: left;
        padding-left: 20px;
    }
}