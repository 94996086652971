.bargraphwrapper{
    margin-bottom: 20px;
    &__revenue{
        color: #383E50;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }
    &__lastsevendays{
        color: color(primary,base);
        font-size: 10px;
    }
}