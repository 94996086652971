.sidebarwrapper {
  position: fixed;
  overflow-y: scroll;
  height: 650px;
  &__logo {
    padding: 20px 110px;
  }
  .accordion-header {
    margin-bottom: -26px;
    margin-top: -15px;
    margin-left: 60px;
  }
  .accordion-item {
    border: none;
  }
  .accordion-button {
    padding: 0;
  }
  .accordion-button::after {
    margin-left: 35px;
  }
  .product-item {
    color: #292f46;
    font-size: 16px;
    font-weight: 700;
  }
  .icon {
    color: #888888;
    font-size: 25px;
  }
  .activeSidebar {
    background-color: #719f31;
    border-radius: 4px;
    color: #fff;
    display: block;
    .icon {
      color: #fff;
    }
  }
  &__unorderlist {
    ul {
      li {
        padding: 15px 20px;
        a {
          color: #292f46;
          font-size: 16px;
          font-weight: 700;
          padding: 13px 60px;
          span {
            margin-left: 17px;
          }
        }
      }
    }
  }
  .subnav {
    color: #383e50;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
  }
  .product-li {
    .product {
      padding: 13px 60px 13px 15px;
    }
    li {
      padding: 8px;
    }
    .product-li-link {
      padding: 13px 10px 13px 60px;
    }
  }
}
.activesubSidebar{
  background-color: #719f31;
  border-radius: 4px;
  color: #fff !important;
  display: block;
}
.subnavitem li a{
  padding: 10px 30px !important;
}
