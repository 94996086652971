.form-control{
    border: none !important;
    background: #EFEFEF !important;
    height: 45px;
}
label{
    margin-bottom: 5px;
    color: #495058;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Lato','sans-serif';
}