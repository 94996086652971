.orderwrapper {
  background-color: #e5e5e5;
  padding: 20px 12px 20px 40px;
  &__title {
    color: #383e50;
    font-size: 24px;
    font-weight: 700;
  }
  &__background {
    background-color: #fff;
    padding: 25px;
    border-radius: 4px;
    &--dateicon {
      background-color: #f4f5f9;
      border-radius: 4px;
      padding: 10px 12px;
      p {
        color: #495058;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
      }
      .icon {
        margin-top: 3px;
        margin-left: 6px;
      }
    }
    &--orderheading {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 20px;
    }
    &--datecalender {
      border: 1px solid #f4f5f9;
      background-color: #fff;
      border-radius: 4px;
      padding: 10px 12px;
      .calendericon {
        color: #495058;
        margin-top: 2px;
        margin-right: 36px;
      }
      input {
        font-size: 12px;
        color: #4f687b;
        font-weight: 400;
        border: none;
      }
    }
    &--headingrow {
      background-color: #f4f5f9;
      border-radius: 4px;
      padding: 12px 0;
      text-align: left;
      padding-left: 20px;
      margin-top: 15px;
      color: #495058;
      font-size: 14px;
      font-weight: 600;
      font-family: "Lato", "sans-serif";
      margin-bottom: 25px;
    }
  }
}
.modal-backdrop.show {
  opacity: 0.1 !important;
}
.ordermodalbg {
  background-color: #fff;
  padding: 33px;
  border-radius: 4px;
  &__title {
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;
  }
  .username {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #383e50;
    margin-top: 15px;
  }
  .descdata {
    font-family: "Lato", "sans-serif";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #495058;
    padding: 5px 0 5px 70px;
  }
  .maindata {
    font-family: "Lato", "sans-serif";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #495058;
    padding: 5px 0;
  }
  .userdetails {
    margin-top: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px 33px;
    position: relative;
    .topic {
      position: absolute;
      background-color: #fff;
      padding: 10px 5px;
      top: -9%;
      color: #495058;
      font-size: 12px;
      font-weight: 500;
    }
  }
  table {
    margin-top: 11px;
  }
}
.modal-data,
.modalheading {
  text-align: left;
  padding-left: 30px;
}
.modal-total {
  border-top: 0.6px solid #e0e0e0;
  padding-top: 14px;
  text-align: left;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 700;
  color: #383e50;
  text-transform: uppercase;
}
.inputreletive {
  position: relative;
}
.inputabsolute {
  position: absolute;
  top: -32% !important;
}
